<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <img alt="logo" height="60" src="@/assets/images/logo/logo.svg"/>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
          class="d-none d-lg-flex align-items-center p-5"
          lg="8"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
              :src="sideImg"
              alt="Register V2"
              fluid
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Reset password-->
      <b-col
          class="d-flex align-items-center auth-bg px-2 p-lg-5"
          lg="4"
      >
        <b-col
            class="px-xl-2 mx-auto"
            lg="12"
            md="6"
            sm="8"
        >
          <b-card-title
              class="font-weight-bold mb-1"
              title-tag="h2"
          >
            Réinitialiser le Mot de passe 🔒
          </b-card-title>
          <b-card-text class="mb-2">
            Votre nouveau mot de passe doit être différent des mots de passe précédemment utilisés
          </b-card-text>

          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form
                class="auth-reset-password-form mt-2"
                method="POST"
                @submit.prevent="validationForm"
            >

              <!-- password -->
              <b-form-group
                  label="nouveau mot de passe"
                  label-for="reset-password-new"
              >
                <validation-provider
                    #default="{ errors }"
                    name="mot de passe"
                    rules="required|password|min:12"
                    vid="Password"
                >
                  <b-input-group
                      :class="errors.length > 0 ? 'is-invalid':null"
                      class="input-group-merge"
                  >
                    <b-form-input
                        id="reset-password-new"
                        v-model="password"
                        :state="errors.length > 0 ? false:null"
                        :type="password1FieldType"
                        class="form-control-merge"
                        name="reset-password-new"
                        placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                          :icon="password1ToggleIcon"
                          class="cursor-pointer"
                          @click="togglePassword1Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- confirm password -->
              <b-form-group
                  label="confirmation de mot de passe"
                  label-for="reset-password-confirm"
              >
                <validation-provider
                    #default="{ errors }"
                    name="confirmation de mot de passe"
                    rules="required|confirmed:Password|min:12"
                >
                  <b-input-group
                      :class="errors.length > 0 ? 'is-invalid':null"
                      class="input-group-merge"
                  >
                    <b-form-input
                        id="reset-password-confirm"
                        v-model="cPassword"
                        :state="errors.length > 0 ? false:null"
                        :type="password2FieldType"
                        class="form-control-merge"
                        name="reset-password-confirm"
                        placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                          :icon="password2ToggleIcon"
                          class="cursor-pointer"
                          @click="togglePassword2Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small v-if="errors[0] == 'confirmation de mot de passe n\'est pas identique'" class="text-danger">Le mot de passe n'est pas identique</small>
                  <small v-else class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit button -->
              <b-button
                  block
                  type="submit"
                  variant="primary"
              >
                Réinitialiser
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link :to="{name:'auth-login'}">
              <feather-icon icon="ChevronLeftIcon"/>
              Retour Connexion
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Reset password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BButton,
  BCardText,
  BCardTitle,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BRow,
} from 'bootstrap-vue'
import { required } from '@validations'

export default {
  components: {
    VuexyLogo,
    BRow,
    BCol,
    BButton,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BImg,
    BInputGroup,
    BLink,
    BFormInput,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      cPassword: '',
      password: '',
      sideImg: require('@/assets/images/login/login_slide.png'),
      // validation
      required,

      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    validationForm() {
      this.$refs.simpleRules.validate()
          .then(success => {
            if (success) {
              this.$http
                  .post('resetPasswordCollaborateur', {
                    token: this.$route.params.token,
                    password: this.password
                  })
                  .then(response => {
                    if (response.data.success) {
                      this.messageToast(response.data.message, 'Succès', 'success')
                      // window.location.href = '/login'
                      this.$router.push({name: "auth-login", params: {username: response.data.data.username}});
                    }
                  })
                  .catch(err => {
                    this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
                  })
            }
          })
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
